import { Box, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import { PromptExecutionSettingsDto } from '../../api-service/clients';
import { ChatTabPanel } from './chat-tab-panel';
import { CitizenAssistantChatbox } from './citizen-assistant-chatbox';
import { FasitAssistantChatbox } from './fasit-assistant-chatbox';
import useChatCitizenContext from './hooks/use-chat-citizen-context';
import { useRestrictedAiFeature } from '../../hooks/ai-search/use-restricted-ai-feature';

export interface IChatComponentProps {
  height?: string;
  tabIndex: number;
  onTabChange: (tabIndex: number) => void;
}

export function ChatComponent(props: IChatComponentProps) {
  const { height, tabIndex, onTabChange } = props;
  const [settings, setSettings] = useState<PromptExecutionSettingsDto>({});
  const { citizenId } = useChatCitizenContext();
  const { isRestrictedAiFeatureEnabled: citizenAssistantEnabled } = useRestrictedAiFeature('AiSearchCitizenAssistant');

  const handleSettingsChange = (property: keyof PromptExecutionSettingsDto, value: any) => {
    setSettings({ ...settings, [property]: value });
  };

  const handleTabChange = (event: React.SyntheticEvent, newTabIndex: number) => {
    onTabChange(newTabIndex);
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {citizenAssistantEnabled ? (
        <Box sx={{ position: 'relative', maxHeight: height ?? '100%', height: height ?? '100%', display: 'flex', flexDirection: 'column' }}>
          <Tabs value={tabIndex} onChange={handleTabChange}>
            <Tab label="Lexi" />
            <Tab label="Summa" disabled={!citizenId} />
          </Tabs>
          <ChatTabPanel value={tabIndex} index={0}>
            <FasitAssistantChatbox onSettingsChange={handleSettingsChange} settings={settings} />
          </ChatTabPanel>
          <ChatTabPanel value={tabIndex} index={1}>
            <CitizenAssistantChatbox onSettingsChange={handleSettingsChange} settings={settings} citizenId={citizenId} />
          </ChatTabPanel>
        </Box>
      ) : (
        <FasitAssistantChatbox onSettingsChange={handleSettingsChange} settings={settings} />
      )}
    </>
  );
}
