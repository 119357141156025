import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { SingleSelect } from '../single-select';
import { AssistantType } from './assistant-type';
import { moduleClientFactory } from '../../api-service/module-client-factory';

export interface IStandardQuestion {
  id: string;
  title: string;
  question: string;
  topic: string;
  isSelectable: boolean;
}

export interface IChatStandardQuestionSelectProps {
  assistantType: AssistantType;
  onStandardQuestionChange: (question: string) => void;
  isLoading?: boolean;
}

export function ChatStandardQuestionSelect(props: IChatStandardQuestionSelectProps) {
  const { assistantType, onStandardQuestionChange, isLoading } = props;
  const [standardQuestion, setStandardQuestion] = useState<IStandardQuestion>();
  const [standardQuestionSelectOptions, setStandardQuestionSelectOptions] = useState<IStandardQuestion[]>([]);
  const client = moduleClientFactory.createAiSearchClient();

  useEffect(() => {
    async function fetchAiStandardQuestions() {
      const result = await client.getAiStandardQuestions({
        aiStandardQuestionArea: assistantType as number,
      });

      const standardQuestions: IStandardQuestion[] =
        result.standardQuestions?.map(q => ({
          id: q.id,
          title: q.title ?? '',
          question: q.question ?? '',
          topic: q.topic ?? '',
          isSelectable: true,
        })) ?? [];

      const uniqueTopics = Array.from(new Set(standardQuestions.map(q => q.topic))).sort((a, b) => b.localeCompare(a));

      const selectOptions: IStandardQuestion[] = uniqueTopics.flatMap(topic => {
        const topicHeader: IStandardQuestion = {
          id: uuidv4(),
          title: topic,
          question: '',
          topic: '',
          isSelectable: false,
        };

        const topicQuestions = standardQuestions.filter(q => q.topic === topic).sort((a, b) => a.title.localeCompare(b.title));

        return [topicHeader, ...topicQuestions];
      });

      setStandardQuestionSelectOptions(selectOptions);
    }
    fetchAiStandardQuestions();
  }, []);

  const handleStandardQuestionChange = (value?: IStandardQuestion) => {
    setStandardQuestion(value);
    onStandardQuestionChange(value?.question ?? '');
  };

  function getPaddingForOption(option?: IStandardQuestion): number {
    if (option?.isSelectable) {
      return 5;
    }
    return 0;
  }

  return assistantType === AssistantType.Citizen ? (
    <Box sx={{ display: 'flex', ml: 2, mb: 2, alignItems: 'center' }}>
      <Box style={{ width: '100%' }}>
        <SingleSelect<IStandardQuestion | undefined>
          label="Standardspørgsmål"
          onChange={handleStandardQuestionChange}
          value={standardQuestion}
          getOptionDisabled={opt => !opt?.isSelectable}
          options={standardQuestionSelectOptions}
          isOptionEqualToValue={(option, val) => option?.title === val?.title}
          getOptionLabel={(opt?: IStandardQuestion) => opt?.title ?? ''}
          renderOption={(prop: object, option?: IStandardQuestion) => (
            <li
              {...prop}
              key={option?.id}
              style={{
                fontWeight: option?.isSelectable ? undefined : 'bolder',
                fontSize: option?.isSelectable ? undefined : '.9rem',
                opacity: 1,
              }}
            >
              <span style={{ paddingLeft: getPaddingForOption(option) }}>{option?.title}</span>
            </li>
          )}
          readOnly={isLoading}
          variant="outlined"
        />
      </Box>
    </Box>
  ) : (
    <div />
  );
}
