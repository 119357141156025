import React from 'react';
import { Chip, Box, CircularProgress, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { Row } from '../row';
import { Cell } from '../cell';
import { ColorPalette } from '../config';
import { TooltipLink } from './tooltip-link';
import { IChatMessage } from '../../hooks/ai-search/use-chat';
import { ChatCitationSummary } from './chat-citation-summary';
import { ChatFeedback } from './chat-feedback';

export type IChatMessageComponent = IChatMessageContentProps;

export function ChatMessageComponent(props: IChatMessageComponent) {
  const { isAssistant } = props;
  return (
    <Row>
      <Cell size="full">
        <Chip
          sx={{
            fontSize: '14px',
            padding: '5px',
            borderRadius: '4px',
            position: 'relative',
            left: '10px',
            maxWidth: 'calc(100% - 50px)',
            minWidth: 'calc(100% - 50px)',
            letterSpacing: '0.2px',
            backgroundColor: isAssistant ? ColorPalette.ApexTintedLight : '#FAFAFA',
            border: isAssistant ? 'solid 1px #CCC' : 'solid 1px #F2F2F2',
            float: isAssistant ? 'right' : 'left',
            color: 'black',
            display: 'block',
            textAlign: 'left',
            height: 'auto',
            '& .MuiChip-label': {
              display: 'block',
              whiteSpace: 'normal',
            },
          }}
          label={<ChatMessageContent {...props} />}
        />
      </Cell>
    </Row>
  );
}

interface IChatMessageContentProps {
  message: IChatMessage;
  isAssistant: boolean;
  onChatFeedbackChange: (msg: IChatMessage) => Promise<void>;
  showCitations?: boolean;
}

function ChatMessageContent(props: IChatMessageContentProps) {
  const { message, onChatFeedbackChange, isAssistant, showCitations } = props;

  const infoPrefix = isAssistant ? 'Besvaret af' : 'Oprettet af';

  return (
    <>
      <Box>
        {message.userName && message.timestamp && (
          <Box sx={{ textAlign: 'right', fontWeight: 'bolder', marginTop: 2, marginBottom: 2 }}>
            {`${infoPrefix} ${message.userName} d. ${message.timestamp}`}
          </Box>
        )}
      </Box>
      <Box m={1}>
        {isAssistant && message.isPreparingReply ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
            <CircularProgress size={24} />
            <Typography variant="body2" sx={{ ml: 2 }}>
              {message.preparationStatusMessage || 'Forbereder svar...'}
            </Typography>
          </Box>
        ) : (
          <ReactMarkdown
            components={{
              a: ({ node, href, children }) => TooltipLink({ node, href, children, chatMessage: message }),
            }}
          >
            {message.content}
          </ReactMarkdown>
        )}
        {showCitations && <ChatCitationSummary citations={message.citations ?? []} />}
        <ChatFeedback msg={message} onChatFeedbackChange={onChatFeedbackChange} />
      </Box>
    </>
  );
}
