import React, { useEffect, useRef, useState } from 'react';
import { faGear, faBriefcaseBlank } from '@fortawesome/pro-light-svg-icons';
import { faArrowAltUp, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { useFeatureToggles } from '../../feature-toggles';
import { CitationSourceType } from '../../hooks/ai-search/use-chat';
import { Checkbox } from '../checkbox';
import { ColorPalette } from '../config';
import { IconButton } from '../icon-button/icon-button';
import { TrashIcon } from '../icons/trash-icon/trash-icon';
import { TextField } from '../text-field';
import { Tooltip } from '../tooltip';
import { PromptSettings } from './settings/prompt-settings';
import { PromptExecutionSettingsDto } from '../../api-service/clients';
import { AssistantType } from './assistant-type';
import { ChatStandardQuestionSelect } from './chat-standard-question-select';
import { LoadingIndicator } from '../loading-indicator';

export interface IChatMessageInputProps {
  citationsTypes: CitationSourceType[];
  isLoading: boolean;
  settings: PromptExecutionSettingsDto;
  assistantType: AssistantType;
  onDataSourceChange: (type: CitationSourceType) => void;
  onSubmitPrompt: (chatInput: string) => void;
  onClearChat: () => void;
  onSettingsChange: (property: keyof PromptExecutionSettingsDto, value: any) => void;
}

const useStyles = makeStyles({
  loadingIndicator: {
    width: '100%',
  },
});

export function ChatMessageInput(props: IChatMessageInputProps) {
  const { citationsTypes, onDataSourceChange, onSubmitPrompt, isLoading, onClearChat, onSettingsChange, settings, assistantType } = props;
  const classes = useStyles();

  const { isFeatureEnabled } = useFeatureToggles();

  const isConfigMenuEnabled = isFeatureEnabled('AiSearchAllowCustomPromptSettings');
  const isIaDocumentsFeatureEnabled = isFeatureEnabled('AiSearchIADocuments');
  const isLegalDocumentsFeatureEnabled = isFeatureEnabled('AiSearchLegalDocuments');
  const showDataSources = isIaDocumentsFeatureEnabled || isLegalDocumentsFeatureEnabled;

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [inputString, setInputString] = useState('');
  const [showConfig, setShowConfig] = useState(false);

  useEffect(() => {
    // Ensure the input is focused only after the drawer is open and the component is fully mounted
    if (inputRef.current) {
      const timeoutId = setTimeout(() => {
        inputRef.current?.focus();
      }, 300);

      return () => clearTimeout(timeoutId);
    }
    return () => {};
  }, []);

  const handleSubmitPrompt = (value?: string) => {
    if ((!value && !inputString) || isLoading) return;
    onSubmitPrompt(value ?? inputString);
    showConfig && setShowConfig(false);
    setInputString('');
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      handleSubmitPrompt();
    }
  };

  const onStandardQuestionChange = (value: string) => {
    setInputString(value);
    handleSubmitPrompt(value);
  };

  function renderDataSources() {
    if (!showDataSources) return null;

    const renderGeneralDataSources = () => (
      <>
        <Checkbox
          label="Fasit vejledninger"
          checked={citationsTypes.includes(CitationSourceType.FasitGuides)}
          onChange={() => onDataSourceChange(CitationSourceType.FasitGuides)}
        />
        {isIaDocumentsFeatureEnabled && (
          <Checkbox
            label="Interne arbejdsgange"
            checked={citationsTypes.includes(CitationSourceType.IaDoduments)}
            onChange={() => onDataSourceChange(CitationSourceType.IaDoduments)}
          />
        )}
        {isLegalDocumentsFeatureEnabled && (
          <Checkbox
            label="Lovtekster"
            checked={citationsTypes.includes(CitationSourceType.LegalDocuments)}
            onChange={() => onDataSourceChange(CitationSourceType.LegalDocuments)}
          />
        )}
      </>
    );

    const renderCitizenDataSources = () => <Checkbox label="Borgers journalnotater" checked disabled />;

    switch (assistantType) {
      case AssistantType.Fasit:
        return renderGeneralDataSources();
      case AssistantType.Citizen:
        return renderCitizenDataSources();
      default:
        return <div />;
    }
  }

  return (
    <Box sx={{ width: '100%', backgroundColor: 'white', display: 'flex', flexDirection: 'column', boxShadow: '0 -2px 10px rgba(0,0,0,0.2)' }}>
      <Box sx={{ color: ColorPalette.SecondaryTextColor, fontSize: '.650rem', p: 1, whiteSpace: 'pre-line', textAlign: 'right' }}>
        Svarene i denne chat er skabt af en generativ AI. Vær kritisk i brugen af svarene
      </Box>
      <Box sx={{ ml: 2, mb: 2, color: ColorPalette.SecondaryTextColor }}>
        <Box>Svar baseres på valgte datakilder</Box>
        {renderDataSources()}
      </Box>
      {isFeatureEnabled('AiSearchStandardQuestionsEnabled') && (
        <ChatStandardQuestionSelect onStandardQuestionChange={onStandardQuestionChange} isLoading={isLoading} assistantType={assistantType} />
      )}
      <Box sx={{ display: 'flex', ml: 2, mb: 2, alignItems: 'center' }}>
        <LoadingIndicator loading={isLoading} className={classes.loadingIndicator}>
          <div style={{ width: '100%' }}>
            <TextField
              inputRef={inputRef}
              style={{ backgroundColor: ColorPalette.WHITE }}
              readOnly={isLoading}
              label="Stil et spørgsmål"
              variant="outlined"
              value={inputString}
              onChange={e => setInputString(e.target.value.replace(/^[\r\n]+/, ''))}
              onKeyDown={handleKeyDown}
            />
          </div>
        </LoadingIndicator>
        <Box>
          <Tooltip title="Send">
            <span>
              <Button
                variant="contained"
                disabled={isLoading || !inputString}
                sx={{ minWidth: 20, backgroundColor: ColorPalette.PrimaryMain, color: ColorPalette.WHITE, height: 45, width: 45, m: 1 }}
                onClick={() => handleSubmitPrompt()}
                size="medium"
              >
                {isLoading ? <FontAwesomeIcon icon={faSpinner} size="xl" /> : <FontAwesomeIcon icon={faArrowAltUp} size="xl" />}
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Box>
      <Box sx={{ marginLeft: '5px', marginBottom: '5px' }}>
        <Tooltip title="Ryd chat">
          <IconButton onClick={onClearChat} style={{ right: '0px' }} size="small">
            <TrashIcon />
          </IconButton>
        </Tooltip>
        {isConfigMenuEnabled && (
          <Tooltip title={showConfig ? 'Skjul indstillinger' : 'Vis indstillinger'}>
            <IconButton onClick={() => setShowConfig(!showConfig)} size="small">
              <FontAwesomeIcon icon={faGear} />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {showConfig && (
        <Box sx={{ color: ColorPalette.SecondaryTextColor, fontSize: '.850rem', pl: 1, pr: 1, pb: 1, whiteSpace: 'pre-line' }}>
          <Box sx={{ marginBottom: 2 }}>
            <h3>Promptindstillinger</h3>
          </Box>
          <PromptSettings promptSettings={settings} onUpdateSettings={onSettingsChange} showRagDocuments={assistantType === AssistantType.Fasit} />
        </Box>
      )}
    </Box>
  );
}
