import React, { PropsWithChildren } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { CircularProgress } from '@mui/material';

interface ILoadingIndicatorProps {
  loading: boolean;
  error?: boolean;
  size?: number;
  className?: string;
}

const intLoadingIndicatorDefaultSize = 24;

const useStyles = makeStyles({
  wrapper: {
    border: (props: ILoadingIndicatorProps) => (props.error ? '2px solid red' : 'none'),
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: (props: ILoadingIndicatorProps) => -((props.size || intLoadingIndicatorDefaultSize) / 2),
    marginLeft: (props: ILoadingIndicatorProps) => -((props.size || intLoadingIndicatorDefaultSize) / 2),
  },
});

export function LoadingIndicator(props: PropsWithChildren<ILoadingIndicatorProps>) {
  const { loading, children, className, size = intLoadingIndicatorDefaultSize } = props;
  const classes = useStyles({ size, ...props });

  return (
    <div className={`${classes.wrapper} ${className || ''}`}>
      {children}
      {loading && <CircularProgress size={size} className={classes.progress} />}
    </div>
  );
}
