/* eslint-disable react/no-array-index-key */
import { useState, useContext, useEffect } from 'react';
import { UserData } from 'react-oidc';
import { v4 as uuid } from 'uuid';
import { getPrompt, getCitizenPrompt, IPrompt } from '../../api-service/clients/ai-search-client';
import { CreateUserChatMessageDto, Vote } from '../../api-service/clients';
import { moduleClientFactory } from '../../api-service/module-client-factory';
import { formatDateTime, generateGuid } from '../../utilities';
import { AssistantType } from '../../components/chat/assistant-type';

export interface IChatMessage {
  content: string;
  role?: 'User' | 'Assistent' | 'Tool' | 'Function' | 'System';
  citations?: ICitation[];
  chatCorrelationId?: string; // Correlates User and Assistent messages
  vote?: Vote;
  qualityComment?: string;
  timestamp?: string;
  userName?: string;
  sessionId?: string;
  isPreparingReply?: boolean; // Indicates if the message is still being generated
  preparationStatusMessage?: string; // Latest update from function calls or similar work being done before the reply strea starts
}

export interface ICitation {
  link: string;
  text: string;
  title: string;
  id: string;
  CitationSourceType: number;
  isAlternativeResult?: boolean;
}
export enum CitationSourceType {
  FasitGuides = 0,
  IaDoduments = 1,
  LegalDocuments = 2,
}

export const useChat = (assistantType: AssistantType) => {
  const [messages, setMessages] = useState<IChatMessage[]>([]);
  const [citationsTypes, setCitationsTypes] = useState<CitationSourceType[]>([CitationSourceType.FasitGuides]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const context = useContext(UserData);
  const [sessionId, setSessionId] = useState<string | undefined>('');

  const userDisplayName = context?.user?.profile?.name ?? 'Ukendt bruger';
  const chatMessageStorageKey = `FASIT_${assistantType}_CHATMESSAGE_KEY`;
  const citationTypesStorageKey = `FASIT_${assistantType}_CITATIONTYPES_KEY`;

  useEffect(() => {
    messages.length > 1 && sessionStorage.setItem(chatMessageStorageKey, JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    const datastr = sessionStorage.getItem(chatMessageStorageKey);
    if (datastr) {
      try {
        const loadedMessages = JSON.parse(datastr);

        if (Array.isArray(loadedMessages)) {
          const loadedSessionId = loadedMessages[1]?.sessionId;
          if (loadedSessionId) {
            setSessionId(loadedSessionId);
          }
          setMessages(loadedMessages);
        }
      } catch (e) {
        console.error('Error parsing datastr:', e, datastr);
      }
    }

    const citationTypesStr = localStorage.getItem(citationTypesStorageKey);
    try {
      if (citationTypesStr) {
        setCitationsTypes(JSON.parse(citationTypesStr));
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(citationTypesStorageKey, JSON.stringify(citationsTypes));
  }, [citationsTypes]);

  const clearChat = () => {
    setSessionId(undefined);
    setMessages([{ content: '' }]);
    sessionStorage.removeItem(chatMessageStorageKey);
  };

  const getSessionId = () => {
    if (!sessionId) {
      const newSessionId = generateGuid();
      setSessionId(newSessionId);
      return newSessionId;
    }

    return sessionId;
  };

  const handlePrompt = async (promptData: IPrompt) => {
    const prompt = { ...promptData, sessionId: getSessionId() };
    setIsLoading(true);
    error && setError(undefined);
    const chatCorrelationId = uuid();
    prompt &&
      prompt.content &&
      setMessages(prev => [
        ...prev,
        {
          content: prompt.content || '',
          role: 'User',
          chatCorrelationId,
          timestamp: formatDateTime(new Date().toISOString()),
          userName: userDisplayName,
          sessionId: prompt.sessionId,
        },
        {
          content: '',
          role: 'Assistent',
          chatCorrelationId,
          isPreparingReply: true,
          preparationStatusMessage: '',
        },
      ]);

    try {
      const user = await context.userManager?.getUser();
      const response = assistantType === AssistantType.Fasit ? await getPrompt(prompt, user?.access_token as string) : await getCitizenPrompt(prompt, user?.access_token as string);

      if (!response?.body || !response.ok) {
        throw new Error();
      }

      const reader = response.body.getReader();
      await readStream(reader);
    } catch (errorMessage) {
      setError('Der skete en fejl under læsning af data');
      setIsLoading(false);
    }
  };

  const readStream = async (reader: ReadableStreamDefaultReader<Uint8Array>) => {
    const decoder = new TextDecoder();
    let buffer = '';

    const processChunk = ({ done, value }: ReadableStreamReadResult<Uint8Array>) => {
      if (done) {
        setIsLoading(false);
        return;
      }

      const chunkText = decoder.decode(value, { stream: true });
      buffer += chunkText;

      const lastIncompleteLine = processBuffer(buffer);
      buffer = lastIncompleteLine;

      reader.read().then(processChunk);
    };

    reader.read().then(processChunk);
  };

  const processBuffer = (buffer: string): string => {
    const lines = buffer.split('\n');
    const lastLine = lines.pop() ?? ''; // Keep the last incomplete part in buffer

    const newChatMessages = lines.filter(line => line.trim()).map(parseJSONLine);

    // Find the last message with role 'Tool' or 'Function' to update the preparation status message
    const toolUpdate = newChatMessages
    .slice()
    .reverse()
    .find(msg => msg?.role === 'Tool' || msg?.role === 'Function');

    // If assistant content is received, update the progress message
    const assistantContent = newChatMessages
      .filter(x => x?.role === 'Assistent' || x?.role === undefined)
      .map(msg => msg?.content)
      .join('');

    const citations = newChatMessages.find(xy => xy?.citations && xy?.citations?.length > 0)?.citations;
    const concatMessage = {
      content: assistantContent,
      Role: 'Assistent',
      citations,
      chatCorrelationId: newChatMessages[0]?.chatCorrelationId,
      isPreparingReply: assistantContent ? false : true, // isPreparingReply is false once we start receiving assistant messages
      preparationStatusMessage: toolUpdate?.content, 
    };
    updateLastMessageContentAndCitations(concatMessage);
    return lastLine;
  };

  const parseJSONLine = (line: string): IChatMessage | null => {
    try {
      const message = JSON.parse(line);

      // Map the integer role to the corresponding IChatMessage role
      const roleMapping: { [key: number]: IChatMessage['role'] } = {
        1: 'System',
        2: 'User',
        3: 'Assistent',
        4: 'Function',
        5: 'Tool',
      };

      return {
        content: message.Content,
        userName: 'Fasit assistent',
        role: roleMapping[message.Role] || undefined,
        citations: message.Citations?.map(
          (citation: any) =>
            ({
              id: citation.Id,
              link: citation.Link,
              title: citation.Title,
              text: citation.Text,
              CitationSourceType: citation.CitationContentType,
              isAlternativeResult: citation.IsAlternative,
            } as ICitation)
        ),
      } as IChatMessage;
    } catch (errorMessage) {
      setError('Der skete en fejl under læsning af data');
      console.error('Error parsing JSON:', errorMessage, line);
      return null;
    }
  };

  const updateLastMessageContentAndCitations = (message: IChatMessage) => {
    setMessages(prevMessages => {
      if (prevMessages.length === 0) {
        return [message];
      }

      const lastMessage = { ...prevMessages[prevMessages.length - 1] };
      lastMessage.content += message.content;
      lastMessage.userName = 'Fasit assistent';
      lastMessage.timestamp = formatDateTime(new Date().toISOString());
      if (message.citations) {
        lastMessage.citations = message.citations;
      }

      // Update with message progress message and loading state
      lastMessage.isPreparingReply = message.isPreparingReply;
      lastMessage.preparationStatusMessage = message.preparationStatusMessage;

      return [...prevMessages.slice(0, -1), lastMessage];
    });
  };

  const updateMessageFeedback = (chatFeedbackMsg: IChatMessage) => {
    setMessages(prevMessages =>
      prevMessages.map(msg =>
        msg.chatCorrelationId === chatFeedbackMsg.chatCorrelationId && msg.role === 'Assistent'
          ? { ...msg, qualityComment: chatFeedbackMsg.qualityComment, vote: chatFeedbackMsg.vote }
          : msg
      )
    );
  };
  const setChatFeedback = async (chatFeedbackMsg: IChatMessage) => {
    const correlatedMessages = messages.filter(msg => msg.chatCorrelationId === chatFeedbackMsg.chatCorrelationId);

    if (correlatedMessages.length === 2) {
      const userMessage = correlatedMessages.find(msg => msg.role === 'User');
      let assistantMessage = correlatedMessages.find(msg => msg.role === 'Assistent');

      if (!userMessage || !assistantMessage) {
        setError('Der skete en fejl');
        return;
      }

      assistantMessage = { ...assistantMessage, qualityComment: chatFeedbackMsg.qualityComment, vote: chatFeedbackMsg.vote };

      const dto: CreateUserChatMessageDto = {
        id: assistantMessage.chatCorrelationId,
        qualityComment: assistantMessage.qualityComment,
        question: userMessage.content,
        response: assistantMessage.content,
        vote: assistantMessage.vote,
      };

      const client = moduleClientFactory.createAiSearchClient();

      await client.createOrUpdateUserChatMessage({ userChatMessageDto: dto });

      updateMessageFeedback(assistantMessage);
    } else {
      setError('Der skete en fejl');
    }
  };

  return { messages, isLoading, handlePrompt, error, clearChat, setChatFeedback, citationsTypes, setCitationsTypes };
};
